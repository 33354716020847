import React from 'react'
import { SECTION_MARGIN } from '../../../utils/constants'
// Types
import { SectionKPI } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading } from '../../../atoms'
import { ContentMd } from '../../commons'
import KpiOperatorIcon from '../../../atoms/KpiOperatorIcon'

const KPI: React.FC<SectionKPI> = (props) => {
  const { id, sectionId, headline, kpis } = props

  return (
    <Box as={'section'} id={sectionId || id}>
      <Box my={SECTION_MARGIN}>
        <Grid>
          {!!headline && (
            <Box mb={[8, 9, 10]}>
              <Row center={'xs'}>
                <Col xs={12} md={10} lg={8}>
                  <Heading as={'h2'} type={'heading1'} textAlign={'center'}>
                    {headline}
                  </Heading>
                </Col>
              </Row>
            </Box>
          )}

          <Row center={'xs'}>
            {kpis.map((k) => (
              <Col key={k.id} xs={12} sm={4}>
                <Box mb={8}>
                  <Flex justifyContent={'center'} alignItems={'center'}>
                    {k.operator && (
                      <Box mr={4}>
                        <KpiOperatorIcon operator={k.operator} />
                      </Box>
                    )}
                    <Heading as={'h3'} type={'display'} textAlign={'center'}>
                      {k.value}
                    </Heading>
                  </Flex>

                  <Box mt={5}>
                    <Heading as={'h4'} type={'heading3'} textAlign={'center'}>
                      {k.label}
                    </Heading>
                    {!!k.description && (
                      <Box textAlign={'center'} px={[0, 0, 4]}>
                        <ContentMd content={k.description} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Col>
            ))}
          </Row>
        </Grid>
      </Box>
    </Box>
  )
}

export default KPI
